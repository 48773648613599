export interface Dispatch {
  type: string;
  payload?: any;
}

export const AUTH_ACTIONS = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGOUT: "LOGOUT",
  SIGNUP: "SIGNUP",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILED: "SIGNUP_FAILED",
  FIELD: "FIELD",
  FETCH_USER_STATS: "FETCH_USER_STATS",
  FETCH_USER_STATS_SUCCESS: "FETCH_USER_STATS_SUCCESS",
  FETCH_USER_STATS_ERROR: "FETCH_USER_STATS_ERROR",
};

export const POST_ACTIONS = {
  ADD_POST: "ADD_POST",
  ADD_POST_SUCCESS: "ADD_POST_SUCCESS",
  ADD_POST_ERROR: "ADD_POST_ERROR",
  EDIT_CURRENT_POST: "EDIT_CURRENT_POST",
  FETCH_POSTS: "FETCH_POSTS",
  FETCH_POSTS_SUCCESS: "FETCH_POSTS_SUCCESS",
  FETCH_POSTS_ERROR: "FETCH_POSTS_ERROR",
  SEARCH_POSTS: "SEARCH_POSTS",
  SET_POST_AUTHOR: "SET_POST_AUTHOR",
  SET_POST_TYPE: "SET_POST_TYPE",
  TOGGLE_TYPE: "TOGGLE_TYPE",
  VIEW_POST: "VIEW_POST",
  HIDE_VIEW_POST: "HIDE_VIEW_POST",
  FETCH_VIEW_POST_MESSAGES: "FETCH_VIEW_POST_MESSAGES",
  FETCH_VIEW_POST_MESSAGES_SUCCESS: "FETCH_VIEW_POST_MESSAGES_SUCCESS",
  FETCH_VIEW_POST_MESSAGES_ERROR: "FETCH_VIEW_POST_MESSAGES_ERROR",
  SET_ACTIVE_MESSAGE_THREAD: "SET_ACTIVE_MESSAGE_THREAD",
  FETCH_POST_MESSAGES_FOR_USER_SUCCESS: "FETCH_POST_MESSAGES_FOR_USER_SUCCESS",
  FETCH_POST_MESSAGES_FOR_USER_ERROR: "FETCH_POST_MESSAGES_FOR_USER_ERROR",
  NOTIFY_DELETE_POST: "NOTIFY_DELETE_POST",
  HIDE_NOTIFY_DELETE_POST: "HIDE_NOTIFY_DELETE_POST",
};

export const NOTIFICATION_TYPES = {
  ADD_FRIEND: "ADD_FRIEND",
  INCOMING_POST_MESSAGE_NOTIFICATION: "INCOMING_POST_MESSAGE_NOTIFICATION",
  FETCH_NOTIFICATIONS: "FETCH_NOTIFICATIONS",
  FETCH_NOTIFICATIONS_SUCCESS: "FETCH_NOTIFICATIONS_SUCCESS",
  FETCH_NOTIFICATIONS_ERROR: "FETCH_NOTIFICATIONS_ERROR",
  READ_NOTIFICATION: "READ_NOTIFICATION",
};
